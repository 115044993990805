<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5 mx-auto">
        <Bread_crumbs />
        <router-link to="/areas" class="c-button cbg-primary mb-3">Back</router-link>
        <div class="card mt-4">
          <div class="card-header">
            <h4 class="card-title">Add VC</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="addVC" id="add-area" class="needs-validation" novalidate>
              <div class="form-group mb-3">
                <!-- <label
                  >Select District <span class="text-danger">*</span></label
                > -->
                <select class="form-control form-control-sm" v-model="area.district_id" v-on:change="filterTehsil()"
                  required>
                  <option value="">Select District... *</option>
                  <option v-for="district in districts" :value="district.id" v-bind:key="district.id">
                    {{ district.title }}
                  </option>
                </select>
                <div class="invalid-feedback text-danger">
                  Please select a district.
                </div>
              </div>
              <div class="form-group mb-3">
                <!-- <label>Select Tehsil <span class="text-danger">*</span></label> -->
                <select class="form-control form-control-sm" v-model="area.tehsil_id" required>
                  <option value="">Select Tehsil... *</option>
                  <option v-for="tehsil in districtTehsils" :value="tehsil.id" v-bind:key="tehsil.id">
                    {{ tehsil.title }}
                  </option>
                </select>
                <div class="invalid-feedback text-danger">
                  Please select a tehsil.
                </div>
              </div>
              <div class="form-group mb-3">
                <span class="text-danger" v-if="this.errors">{{ this.errors.title }}</span>
                <input type="text" class="form-control form-control-sm" placeholder="Title *" required
                  v-model="area.title" />
                <div class="invalid-feedback text-danger">
                  Please provide a title.
                </div>
              </div>
              <div class="form-group mb-3">
                <label>Latitude <span class="text-danger">*</span></label>
                <input type="number" class="form-control form-control-sm" required v-model="area.lat" />
                <div class="invalid-feedback text-danger">
                  Please provide a latitude.
                </div>
              </div>
              <div class="form-group mb-3">
                <label>Longitude<span class="text-danger">*</span></label>
                <input type="number" class="form-control form-control-sm" required v-model="area.long" />
                <div class="invalid-feedback text-danger">
                  Please provide a longitude.
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <button type="submit" class="c-button cbg-primary">
                    Submit
                  </button>
                  <button type="button" @click="this.$refs.addVC.reset()" class="ms-2 c-button cbg-secondary">
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bread_crumbs from "@/components/bread_crumbs.vue";
import { toRaw } from "vue";
import helpers from "@/helpers/helpers";

export default {
  components: {
    Bread_crumbs,
  },
  mixins: [helpers],
  data() {
    return {
      area: {
        tehsil_id: "",
        district_id: "",
        title: "",
        lat: null,
        long: null,
      },

      loading: false,
      districts: [],
      tehsils: [],
      districtTehsils: [],
    };
  },
  mounted() {
    this.fetchDistricts();
    this.fetchTehsils();
  },
  methods: {
    submit() {
      var form = document.getElementById("add-area");
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
      } else {
        this.loading = true;
        const data = toRaw(this.area);
        this.$store
          .dispatch("areas/store", { data })
          .then((res) => {
            this.loading = false;
            if (res.success == true) {
              this.$toast.show("Successfuly added.", {
                theme: "outline",
                position: "top",
                type: "success",
                duration: 5000,
              });
              this.area = {
                tehsil_id: "",
                district_id: "",
                title: "",
                lat: null,
                long: null,
              },
                this.$refs.addVC.reset();
              this.errors = [];
            } else {
              this.errors = this.formatErrors(res.error);
              this.$toast.show("Please fill the required fields in correct format!", {
                theme: "outline",
                position: "top",
                type: "error",
                duration: 10000,
              });
            }
          })
          .catch((e) => {
            this.loading = false;
            this.$toast.show(e.error, {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 10000,
            });
          });
      }
    },

    fetchDistricts(paginate = "false") {
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("districts/getAll", { paginate })
        .then((response) => {
          this.districts = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    fetchTehsils(paginate = "false") {
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("tehsils/getAll", { paginate })
        .then((response) => {
          this.tehsils = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },

    filterTehsil() {
      this.districtTehsils = this.tehsils.filter(
        (tehsil) => tehsil.district_id == this.area.district_id
      );
    },
  },
};
</script>