export var menuItems = {
  data: [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: "dashboard",
      iconf: "fill-home",
      type: "link",
      active: false,
      public: true,
    },
    {
      title: "Administration",
      icon: "administration",
      iconf: "fill-user",
      type: "sub",
      active: false,
      module: ['view_sub_admin', 'view_role'],
      children: [
        {
          path: "/admins",
          title: "Admins",
          type: "link",
          active: false,
          module: "view_sub_admin",
        },
        {
          path: "/roles",
          title: "Roles",
          type: "link",
          active: false,
          module: "view_role",
        },
      ],
    },
    {
      title: "App User",
      path: "/app-users",
      icon: "app-users",
      iconf: "fill-user",
      type: "link",
      active: false,
      module: "view_app_user",
    },
    {
      title: "Doctors",
      path: "/doctors",
      icon: "doctors",
      iconf: "fill-user",
      type: "link",
      active: false,
      module: "view_doctor",
    },
    {
      title: "CHW",
      path: "/chw",
      icon: "chw",
      iconf: "fill-user",
      type: "link",
      active: false,
      module: "view_chw",
    },
    {
      title: "Task Management",
      path: "/tasks",
      icon: "tasks",
      iconf: "fill-user",
      type: "link",
      active: false,
      module: "view_task",
    },
    {
      title: "Campaign",
      path: "/campaigns",
      icon: "campaigns",
      iconf: "fill-user",
      type: "link",
      active: false,
      module: "view_campaign",
    },
    {
      title: "Dynamic Forms",
      path: "/dynamic-forms",
      icon: "dynamic-forms",
      iconf: "fill-user",
      type: "link",
      active: false,
      module: "view_form",
    },
    {
      title: "Categories",
      path: "/categories",
      icon: "categories",
      iconf: "fill-user",
      type: "link",
      active: false,
      module: "view_hlc",
    },
    {
      title: "Interests",
      path: "/interests",
      icon: "interests",
      iconf: "fill-user",
      type: "link",
      active: false,
      module: "view_hlc",
    },
    {
      title: "User Messages",
      path: "/user-messages",
      icon: "user-messages",
      iconf: "fill-user",
      type: "link",
      active: false,
      module: "view_hlc",
    },
    {
      title: "User Requests",
      path: "/appointment-requests",
      icon: "appointment-requests",
      iconf: "fill-user",
      type: "link",
      active: false,
      module: "view_hlc",
    },
    {
      title: "Health Literacy Content",
      path: "/health-literacy-content",
      icon: "health-literacy-content",
      iconf: "fill-user",
      type: "link",
      active: false,
      module: "view_hlc",
    },
    {
      title: "Facility Centers",
      path: "/facility-centers",
      icon: "facility-centers",
      iconf: "fill-user",
      type: "link",
      active: false,
      module: "view_facility_center",
    },
    {
      title: "Location Management",
      icon: "location",
      iconf: "fill-user",
      type: "sub",
      active: false,
      module: ['view_location'],
      children: [
        {
          path: "/districts",
          title: "District",
          type: "link",
          active: false,
          module: "view_location",
        },
        {
          path: "/tehsils",
          title: "Tehsil",
          type: "link",
          active: false,
          module: "view_location",
        },
        {
          path: "/areas",
          title: "VC",
          type: "link",
          active: false,
          module: "view_location",
        },
      ],
    },
    {
      title: "Feeback & Suggestion",
      path: "/feedbacks",
      icon: "feedbacks",
      iconf: "fill-user",
      type: "link",
      active: false,
      module: "view_location",
    },
    {
      title: "Supports",
      path: "/supports",
      icon: "supports",
      iconf: "fill-user",
      type: "link",
      active: false,
      module: "view_location",
    },
    {
      title: "Setting",
      icon: "settings",
      iconf: "fill-user",
      type: "sub",
      active: false,
      module: ['view_location'],
      children: [
        {
          path: "/qualifications",
          title: "Qualifications",
          type: "link",
          active: false,
          module: "view_location",
        },
        {
          path: "/specializations",
          title: "Specializations",
          type: "link",
          active: false,
          module: "view_location",
        },
      ],
    },
    {
      title: "Web Content",
      icon: "faqs",
      iconf: "fill-user",
      type: "sub",
      active: false,
      module: ['view_location'],
      children: [
        {
          path: "/promotional",
          title: "Promotional",
          type: "link",
          active: false,
          module: "view_location",
        },
        {
          path: "/pages",
          title: "Pages",
          type: "link",
          active: false,
          module: "view_location",
        },
        {
          path: "/faqs",
          title: "FAQs",
          type: "link",
          active: false,
          module: "view_location",
        },
      ],
    },
  ],
};
